html, body{
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;  
    margin: 0;
    padding: 0;
    background-color: black;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


/* BUTTON CSS  */
.buttonMain {
    padding: 10px 40px;
    border: 1px solid white;
    background-color: black;
    color: white;
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.buttonMain:hover {
    background-color: white;
    color: black;
}

.buttonMain2{
    width: 100%;
    background: #d49400;
    color: #000;
    border: 1px solid #d49400;
    padding: 10px 40px;
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.buttonMain2:hover {
    background-color: #000;
    color: #d49400;
}

/* FONT  */
@font-face{
    font-family: myFont;
    src:url('./Fonts/Garet-Book.otf');
}
*{
    font-family: myFont;
}

@font-face{
    font-family: myFontOffice;
    src:url('./Fonts/GothamOffice-Bold.otf');
}
.fontOffice{
    font-family: myFontOffice;
}

@font-face{
    font-family: myFontBold;
    src:url('./Fonts/Aire\ Bold\ Std.ttf');
} 
h1, .fontSpecial{
    font-family: myFontBold;
}

/* TEXT HIGHLIGHT  */
::selection {
    background: #d49400;
    color: #000;
  }
 
/* SCROLL BAR  */
    ::-webkit-scrollbar {
    display: none;
}

/* NAVBAR CODE  */
nav{
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center; 
    align-items: center;
    height: 80px;
    width: 100vw;
}
nav ul{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    width: 100%;
    gap: 30px;
    padding-right: 30px;
}
nav ul li{
    display: inline-block;
    line-height: 50px;
}

.checkbtn{
    font-size: 30px;
    color: white;
    float: right;
    line-height: 50px;
    margin-right: 20px;
    cursor: pointer;
    display: none;
}
#check{
    display: none;
}

.nav-link {
    font-size: 13px;
    color: white;
    text-decoration: none;
}

.nav-link:hover {
    color: #82adff;
}

.nav-link.active {
    color: grey;
}


@media (max-width: 858px) {
    nav{
        justify-content: flex-start;
    }
    .Burger{
        margin-right: 20px;
    }
    .checkbtn {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100vw;
        height: 50px;
        margin: 0;
        padding: 0;
        position: absolute;
        z-index: 1;
    }
    .nav-link {
        font-size: 20px;
        color: white; 
    }
    nav ul {
        position: fixed;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        margin: 0;
        padding: 20px;
        width:50vw;
        height: 100vh;
        background-color: black;
        top: 0px; 
        right: -100%;
        text-align: center;
        transition: all 0.5s;
        flex-direction: column;
        z-index: 200;
        gap: 0px;
        background-image: url('../SectionImages/MangoTexture.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
    nav ul li {
        display: block;
        margin: 10px;
        line-height: 30px;
        color: white;
    }

    #check:checked ~ ul {
        right: 0;
    }
    .nav-link{
        font-size: 17px;
    }
}

/* Home.module.css */
@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}


.headerText {
    font-size: 15vw;
    color: white;
    animation: fadeInDown 1.5s ease-in-out;
    transition: transform 1s ease; /* Smooth transition effect */
    cursor: pointer;
    width: 95%;
    text-align: center;
}

.headerText:hover {
    transform: translateY(-5px); /* Lift slightly up on hover */
}


@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translateX(100px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}


.headerLogos {
    font-size: 15vw;
    color: white;
    animation: fadeInLeft 1.5s ease-in-out;
    transition: transform 1s ease; /* Smooth transition effect */
    cursor: pointer;
    width: 95%;
    text-align: center;
}

.headerLogos:hover {
    transform: translateY(-5px); /* Lift slightly up on hover */
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.rotator {
    animation: rotate 30s linear infinite;
}


/* HOME PAGE CAROUSEL VERTICAL  */
.con {
    width: 100vw;
    height: 100vh;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    overflow-x: hidden;
  }
.con section {
    scroll-snap-align: start;
    width: 100%;
    height: 100vh;
}
.con section:nth-of-type(even) {
    color: white;
    height: 100vh;
}



/* ABOUT SECTION CSS  */
.AboutImgTextDiv{
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
  }

  .AboutImgHolder{
    width: 57%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    padding: 20px
  }
  .AboutTextHolder{
    width: 43%;
    display: flex;
    padding: 20px;
    text-align: left;
    flex-direction: column;
  }
  .MaxWidthImage{
    width: 40vw;
    border-radius: 500px;
  }

.HeaderText{
    font-size: 50px;
    margin-top: -3px;
    margin-bottom: -3px;
}
.contactdiv {
    border: 0.5px solid #d49400;
    gap: 10px;
    width: 100%;
    height: 100%;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box; /* Add this */
    background-color: black;
  }
  
  .imagediv {
    width: 100%;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.5px solid #d49400;   
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../SectionImages/HeroCover.png'); 
    box-sizing: border-box; /* Add this */
  } 
  
  input, textarea, select {
    border: 0.5px solid #d49400;
    color: #d49400;
    background: black;
    padding: 10px;
    width: 100%;
    box-sizing: border-box; /* Add this */
  }
  

.hoverbw {
    filter: grayscale(100%);
    transition: filter 0.5s ease;
    cursor: pointer;
}

.hoverbw:hover {
    filter: grayscale(0%);
}

.leftAboutTwo {
    clip-path: polygon(0 0, 100% 0%, 80% 100%, 0% 100%);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 50%;
    height: 100vh;
    background-color: black;
}

.rightAboutTwo {
    clip-path: polygon(20% 0%, 100% 0%, 100% 100%, 0 100%);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 50%;
    height: 100vh;
    background-color: black;
}

.HomeIcon{
    font-size: 40px;
    color: white;
}

.HomeIcon:hover{
    color: #d49400;
}

.imageZoom {
    width: 250px;
    height: 250px;
    position: relative;
    overflow: hidden;
    transition: transform 0.5s;
    cursor: pointer;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageOfferingsItem {   
    width: 300px;
    height: 300px;
    position: relative;
    overflow: hidden;
    transition: transform 0.5s;
    cursor: pointer;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageOfferingsItem:hover {
    transform: scale(1.02);
}

.imageOfferingsItem p {
    font-size: 18px;
}


.imageOfferingsItem2 {   
    width: 300px;
    height: 300px;
    position: relative;
    overflow: hidden;
    transition: transform 0.5s;
    cursor: pointer;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imageOfferingsItem2:hover {
    transform: scale(1.02);
}

.imageOfferingsItem2 p {
    font-size: 18px;
}

.ImageZoomDivier {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    gap: 15px;
  }
  


.mainOrderDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60vw;
    flex-direction: column;
}

.imageZoom:hover {
    transform: scale(1.02);
}

.imageZoom p {
    font-size: 18px;
}
.imageZoomtexty {
    font-size: 25px;
}

.testimonial{
    font-size: 2vw;
}

@media screen and (max-width: 1000px) { 
    .imageOfferingsItem2{
        height: 230px;
        width: 230px;
    }
    .paddingDedo{
        padding: 100px 0px;
    }
    .makemeColumn{
        flex-direction: column;
    }
    .HeaderText{
        font-size: 27px;
        width: 95vw;
    }
    .AboutImgTextDiv{
        flex-direction: column;
    }
    .AboutImgHolder{
        width: 100%;
    }
    .AboutTextHolder{
        width: 100%;
        text-align: center;
        padding: 10px;
        justify-content: center;
        align-items: center;
    }
    .DPhider{
        display: none;
    }
    .MaxWidthImage{
        width: 60vw;
    }
    .texterpara{
        font-size: 14px;
        width: 95vw;
        text-align: center;
    }
    .contactdiv{
        width: 100%;
        padding: 15px;
    }
    .imageZoom {
        width: 150px;
        height: 150px;
    }
    .imageZoomtexty {
        font-size: 20px;
    }
    .mainOrderDiv{
        width: 95vw;
    }
    .makeColumn{
        flex-direction: column;
    }

    .ImageZoomDivier {
        justify-content: center;
    }
    

    .leftAboutTwo {
        /* clip-path: polygon(0 0, 100% 0%, 100% 80%, 0% 100%); */
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
        width: 100vw;
        height: 50vh;
    }
    
    .rightAboutTwo {
        /* clip-path: polygon(0% 20%, 100% 0%, 100% 100%, 0 100%); */
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0 100%);
        width: 100vw;
        height: 50vh;
    }
      
}

